mcWeb.receipt = new function () {

    this.events = {
    }

    this.vm = function (id) {
        var $ = jQuery;
        var self = this;
        this.id = "#" + id;
        this.langService = new LanguageService();
        this.expressCustomer = ko.observable(false);
        this.showCheckoutConfirmation = ko.observable(false);
        this.showReceiptInformation = ko.observable(true);

        this.showSantanderApproved = ko.observable(false);
        this.showSantanderManual = ko.observable(false);
        this.santanderIdentUrl = ko.observable();

        this.showSantanderPayeverApproved = ko.observable(false);
        this.showSantanderPayeverManual = ko.observable(false);

        this.load = function (data, settings) {
            if (data.ExpressCustomer)
                self.expressCustomer(true);

            self.showCheckoutConfirmation(false);
            self.GetCheckoutConfirmationSnippet();

            self.showSantanderApproved(false);
            self.showSantanderManual(false);
            self.santanderIdentUrl("");
            self.GetSantanderApplicationResult();

            self.showSantanderPayeverApproved(false);
            self.showSantanderPayeverManual(false);
            self.GetSantanderPayeverApplicationResult();
        };

        this.GetSantanderPayeverApplicationResult = function () {
            var serviceLocal = new WebApiService("Payment");
            serviceLocal.GetAction("GetSantanderPayeverApplicationResult", null).done(function (result) {
                if (result == null || result.length < 1) {
                    return;
                }

                var currentPaymentMethod = result.CurrentPaymentMethod;
                if (currentPaymentMethod !== "SantanderPayever") return;

                var json = JSON.parse(result.ExtraInfoJson);

                if (json.Status == "APPROVED") {
                    self.showSantanderPayeverApproved(true);
                    self.showReceiptInformation(false);
                } else if (json.Status == "MANUAL") {
                    self.showSantanderPayeverManual(true);
                    self.showReceiptInformation(false);
                }
            });
        }

        this.GetSantanderApplicationResult = function() {
            var serviceLocal = new WebApiService("Payment");
            serviceLocal.GetAction("GetSantanderApplicationResult", null).done(function(result) {
                if (result == null || result.length < 1) {
                    return;
                }

                var currentPaymentMethod = result.CurrentPaymentMethod;
                if (currentPaymentMethod !== "Santander") return;

                var json = JSON.parse(result.ExtraInfoJson);

                //self.santanderIdentUrl(json.UrlIdentificationGEMB);
                //self.santanderIdentUrl(json.ApplicantSignReferenceUrl);

                self.santanderIdentUrl(json.UrlIdentificationGEMB);
                if (json.UrlIdentificationGEMB === "")
                    self.santanderIdentUrl(json.ApplicantSignReferenceUrl);

                if (json.Status == "APPROVED") {
                    self.showSantanderApproved(true);
                    self.showReceiptInformation(false);
                } else if (json.Status == "MANUAL") {
                    self.showSantanderManual(true);
                    self.showReceiptInformation(false);
                }
            });
        };

        this.GetCheckoutConfirmationSnippet = function() {
            var serviceLocal = new WebApiService("Payment");
            serviceLocal.GetAction("GetCheckoutConfirmationSnippet", null).done(function(result) {
                if (result === null || result.length < 1 || result.Snippet.length === 0) {
                    return;
                }

                if (result.UseSoftlog) {
                    if (result.CustomerEmail !== mcWeb.currentUser.getUser())
                        mcWeb.currentUser.setUser(result.CustomerEmail);
                }

                self.showCheckoutConfirmation(true);
                $("#klarna-confirmation-snippet").html(result.Snippet);
            });
        };
    };
};
