mcWeb.loginCheckout = new function () {
    var $ = jQuery;

    this.events = {
        onShowHideLogin: "mcWeb.loginCheckout.events.onShowHideLogin",
        onShowLoginEmail: "mcWeb.loginCheckout.events.onShowLoginEmail",
        onShowLoginPassword: "mcWeb.loginCheckout.events.onShowLoginPassword",
        onChangedEmail: "mcWeb.loginCheckout.events.onChangedEmail"
    };

    this.vm = function (id) {
        var KCO_BETBETID = 167;

        var self = this;
        this.id = "#" + id;
        this.service = new WebApiService("CustomerRegistration");

        this.isLoggedIn = ko.observable(false);
        this.isSoftLoggedIn = ko.observable(false);
        this.forceLogin = ko.observable(false);

        this.showSearchBtn = ko.observable(true);
        this.showSearchSpin = ko.observable(false);

        this.showPswPanel = ko.observable(false);
        this.showPswBtn = ko.observable(false);
        this.showPswSpin = ko.observable(false);

        this.showNewCustomerBtn = ko.observable(false);

        this.email = ko.observable('');
        //this.email.subscribe(function (epost) {
            
        //    if (self.showPswPanel()) {
        //        self.showPswPanel(false);
        //    }
        //    if (!self.showSearchBtn()) {
        //        self.showSearchBtn(true);
        //    }
        //});

        this.psw = ko.observable('');

        this.isEmailSelected = ko.observable(true);
        this.onBlurMail = ko.observable(false);
        this.onBlurMail.subscribe(function () {
            self.isEmailSelected(false);
        });

        this.isPswSelected = ko.observable(false);

        this.showErrorLogin = ko.observable(false);
        this.showBlockedLogin = ko.observable(false);

        this.showCustomerRegistration = ko.observable(false);

        this.onlyExpressCheckout = ko.observable(false);
        this.paymentId = ko.observable();
        this.isCheckout = ko.observable(false);
        this.checkoutRequirePassword = false;
        this.useCustomerRegistration = false;

        this.registration = mcWeb.customerRegistration.instances['checkout-registration'];

        this.data = null;

        var pswt = "********************";
        var clearStorage = function () {
            window.StorageService.localStorage.removeItem('user');
            window.StorageService.localStorage.removeItem('id');
            window.StorageService.localStorage.removeItem('remember');
            window.StorageService.localStorage.removeItem('authenticatedGuid');
        };

        var setLocalStorage = function () {

            var user = mcWeb.currentUser.getUser();

            if (user !== null) {
                self.email(user);
            }
            setCheckoutState(false);
        };

        this.load = function (data, settings) {

            if (settings !== null) {

                self.isCheckout($('.body-checkout').length > 0);

                var user = mcWeb.currentUser.getUser();

                if (!settings.IsLoggedIn && settings.SecuritySoftening && user !== null) {
                    self.email(user);
                }
                self.securitySoftening = settings.SecuritySoftening;
                
                self.isSoftLoggedIn(settings.IsSoftLoggedIn);
                self.isLoggedIn(settings.IsLoggedIn || settings.ReadOnly || (settings.SecuritySoftening && user !== null));
                self.checkoutRequirePassword = settings.CheckoutRequirePassword;
                self.useCustomerRegistration = settings.ShowCustomerRegistration;

                //if ((self.isCheckout()) && (!self.checkoutRequirePassword)) {
                //    self.showRememberMe(false);
                //    window.StorageService.localStorage.setItem('remember', true);
                //}
                //self.securitySoftening = settings.SecuritySoftening;
            }
            if (self.isLoggedIn() || self.isSoftLoggedIn()) {

                setCheckoutState(false);
                return;
            }

            setLocalStorage();
        };

        //Check E-mail
        var setCheckoutState = function (newCustomer) {
            var param = null;
            if (!newCustomer) {
                param = {
                    WebBrukerId: self.email(),
                    NewCustomer: false
                };
            } else {
                param = {
                    WebBrukerId: '',
                    NewCustomer: true
                };
            }
            self.showSearchSpin(true);
            self.service.PostAction("CheckNextCheckoutStage", param).done(function (result) {
                self.showSearchSpin(false);
                if (result && result.Success) {
                    switch (result.NextStage) {
                        case "InActive":
                            InActive();
                            return;
                        case "DoSoftLogin":
                            DoSoftLogin();
                            return;

                        case "ShowUserName":
                            ShowUserName();
                            //GetUserDetails();
                            break;

                        case "ShowUserNameAndPassword":
                            showUserNameAndPassword(result.AutoFillUserName);
                            PubSub.publish(mcWeb.loginCheckout.events.onShowHideLogin, null);
                            break;

                        case "ShowUserDetails":
                            ShowUserDetails();
                            break;

                        case "ShowCustomerRegistration":
                            ShowCustomerRegistration();
                            break;
                    }
                }
            });

        };

        var ShowCustomerRegistration = function () {
            self.showCustomerRegistration(true);
            self.showNewCustomerBtn(false);
            self.showPswPanel(false);
            self.isPswSelected(false);
            self.showPswBtn(false);
            self.showSearchSpin(false);
            self.forceLogin(false);

            PubSub.publish(mcWeb.customerRegistration.events.onChangeEmail, self.email());
            PubSub.publish(mcWeb.loginCheckout.events.onShowHideLogin, null);
        };

        var ShowUserDetails = function () {
            self.showCustomerRegistration(false);
            self.showNewCustomerBtn(false);
            self.showPswPanel(false);
            self.isPswSelected(false);
            self.showPswBtn(false);
            self.showSearchSpin(false);
            self.forceLogin(false);
            PubSub.publish(mcWeb.loginCheckout.events.onShowHideLogin, null);
            GetUserDetails();
        };

        var ShowUserName = function () {
            self.showCustomerRegistration(false);
            self.showSearchBtn(true);
            self.showPswPanel(false);
            self.showPswBtn(false);
            self.showNewCustomerBtn(false);
            self.forceLogin(false);
            self.setLoginFocus(true);
            PubSub.publish(mcWeb.loginCheckout.events.onShowHideLogin, null);
        };

        var InActive = function() {
            self.showBlockedLogin(true);
            self.showCustomerRegistration(false);
            self.showSearchSpin(false);
        };

        var DoSoftLogin = function () {
            self.showCustomerRegistration(false);
            self.showPswPanel(false);
            self.showNewCustomerBtn(false);
            self.showPswBtn(false);
            self.forceLogin(false);
            mcWeb.currentUser.doSecuritySofteningLogin(self.email());
        };

        var showUserNameAndPassword = function (optionalUserName) {
            self.showSearchBtn(false);
            self.showCustomerRegistration(false);

            self.showNewCustomerBtn(self.useCustomerRegistration);

            self.showPswPanel(true);
            self.showPswBtn(true);
            self.showSearchSpin(false);
            if (optionalUserName !== undefined && optionalUserName !== null && optionalUserName.length > 0) 
                self.email(optionalUserName);

            self.setLoginFocus();
            self.forceLogin(true);

        };

        var GetUserDetails = function () {
            var data = {
                email: self.email(),
                zipCode: null,
                isCheckout: self.isCheckout()
            };

            self.service.PostAction("IsRegistered", data).done(function (result) {

                self.showSearchSpin(false);
                self.showPswSpin(false);

                if (result === null) {
                    return;
                }

                if (result.useSoftlog && self.checkoutRequirePassword === true) {
                    self.showPswPanel(true);
                    self.isPswSelected(true);
                    self.showPswBtn(true);
                    return;
                }

                var epost = (result.epost === null || result.epost.length < 2) ? self.email() : result.epost;

                result.epost = epost;
                showRegistration(result);
            });

        };

        this.runCheckEmail = function () {
            setCheckoutState();
        };

        var showRegistration = function (data) {

            if (data === null) {
                self.showPswSpin(false);
                self.showPswBtn(true);
                return;
            }

            self.showCustomerRegistration(true);
            PubSub.publish(mcWeb.customerRegistration.events.onChangedCustomerData, data);
            PubSub.publish(mcWeb.loginCheckout.events.onShowHideLogin, null);

        };

        this.newCustomer = function () {
            if (self.isLoggedIn() || self.isSoftLoggedIn()) {
                PubSub.publish(mcWeb.customerRegistration.onRememberedUserNotMe, null);

                var service = new WebApiService("CustomerRegistration");
                service.GetAction("ChangeSessionToDefaultCustomer").done(function (result) {
                    window.location.reload();
                });
            } else
                setCheckoutState(true);
        };

        this.onClickCheckEmail = function (d, e) {
            self.showErrorLogin(false);
            self.showBlockedLogin(false);
            if (e.keyCode === 13 || e.type === "click") {

                //window.StorageService.localStorage.removeItem('authenticatedGuid');

                if (self.email() !== null && self.email().length > 0) {
                    setCheckoutState();
                } else {
                    clearStorage(false);
                    self.showErrorLogin(true);
                }
                return false;
            }
            return true;
        };

        var login = function () {

            try {

                var psw = self.psw();
                var localstorge = (psw === pswt);
                var id = window.StorageService.localStorage.getItem('id');
                var pass = (id !== null && localstorge) ? id : psw;

                if (pass === null || psw === pswt) {
                    clearStorage();
                    PubSub.publish(mcWeb.lightbox.events.onHideSpinicon);
                    $("#error-msg-login").show();
                    return;
                }

                self.showPswBtn(false);
                self.showPswSpin(true);

                var data = {
                    username: self.email(),
                    password: pass,
                    localstorge: localstorge,
                    allowasynclogin: false,
                    isCheckout: self.isCheckout(),
                    IsNormalMode: self.email().indexOf('@multicase.no') > 0
                };

                var api = new WebApiService("Login");
                api.PostAction("Login", data).done(function (result) {

                    self.showPswSpin(false);
                    if (result.Success === true) {

                        var remove = true;
                        var rember = window.StorageService.localStorage.getItem('remember');
                        if (rember !== null && rember === 'true') {

                            var user = mcWeb.currentUser.getUser();
                            if (user !== null && user === result.User) {
                                window.StorageService.localStorage.setItem('id', result.Id);
                                remove = false;
                            }
                        }

                        if (remove) {
                            window.StorageService.localStorage.removeItem('id');
                            window.StorageService.localStorage.removeItem('remember');
                        }

                        var data = {
                            email: self.email(),
                            zipCode: null,
                            isCheckout: self.isCheckout(),
                            authenticationGuid: result.AuthenticatedUserGuid
                        };

                        PubSub.publish(mcWeb.cart.events.onChangedCart, null);

                        self.service.PostAction("IsRegistered", data).done(function (data) {

                            PubSub.publish(mcWeb.login.events.onIsloggedIn, true);
                            PubSub.publish(mcWeb.payments.events.reloadPayments, null);
                            showRegistration(data);
                        });
                    }
                    else {
                        self.showPswSpin(false);
                        self.showPswBtn(true);
                        clearStorage(false);
                        self.showErrorLogin(true);
                    }

                    return false;
                });
            } catch (ex) {
                alert("Exception: " + ex);
            }
        };

        this.onClickLogin = function (d, e) {

            self.showErrorLogin(false);
            self.showBlockedLogin(false);

            if (e.keyCode === 13 || e.type === "click") {
                if (self.psw() !== null && self.psw().length > 4) {
                    login();
                } else {
                    self.showErrorLogin(true);
                }
                return false;
            }
            return true;
        };

        this.setLoginFocus = function (forceEmail) {
            if (forceEmail) {
                self.isPswSelected(false);
                self.isEmailSelected(true);
            } else {
                var emailKnown = (self.email() !== null && self.email().length > 0);
                self.isPswSelected(emailKnown);
                self.isEmailSelected(!emailKnown);
            }
        };


        PubSub.subscribe(mcWeb.cart.events.hasCartItems, function (message, hasCartItems) {

            if (hasCartItems === true) {
                PubSub.publish(mcWeb.customerRegistration.events.onChangedCustomerData, null);
                PubSub.publish(mcWeb.cart.events.focusDiscoutCode);
            }

        });


        PubSub.subscribe(mcWeb.customerRegistration.onRememberedUserNotMe, function () {
            clearStorage(true);
            self.email('');
        });

        PubSub.subscribe(mcWeb.loginCheckout.events.onShowLoginEmail, function (message, paymentId) {
            self.isLoggedIn(false);
            self.showCustomerRegistration(false);
            $('#payments-tab-nav').slideUp(800);

            self.showSearchBtn(true);
            PubSub.publish(mcWeb.loginCheckout.events.onShowHideLogin, paymentId);
        });

        PubSub.subscribe(mcWeb.loginCheckout.events.onShowLoginPassword, function (message, paymentId) {
            self.isLoggedIn(false);
            self.showCustomerRegistration(false);
            $('#payments-tab-nav').slideUp(800);

            self.showPswPanel(true);
            self.showPswBtn(true);

            if (mcWeb.responsive.getScreenSize() !== 'xs') {
                $("#checkout-email").focus();
            }
            showUserNameAndPassword();
            PubSub.publish(mcWeb.loginCheckout.events.onShowHideLogin, paymentId);
        });

        PubSub.subscribe(mcWeb.loginCheckout.events.onShowHideLogin, function (message, paymentId) {
            if (paymentId !== null) self.paymentId(paymentId);
            else paymentId = self.paymentId();

            if (self.isLoggedIn() || self.showCustomerRegistration() || (self.isSoftLoggedIn() && !self.checkoutRequirePassword && !self.forceLogin() )) {

                var nav = $('#payments-tab-nav');
                nav.slideDown(800);
                $('#checkout-login').fadeOut("slow", function () {

                    $('.registration-type').hide();
                    $('.payment-title').slideDown("slow", function () {

                        $('.registration-type').fadeIn(400);
                        $('.klarna-konto').fadeIn(400);
                        $('.santander-partpayment').fadeIn(400);
                        $('.santander-invoice').fadeIn(400);
                        $('.sveawebpay').fadeIn(400);
                        $('.gothia').fadeIn(400);
                        $("#OrgnrSsn").fadeIn(400);

                        $('.tab-content').removeClass('tab-radius');

                        var showBtn = true;
                        if (paymentId === KCO_BETBETID) showBtn = false;

                        PubSub.publish(mcWeb.checkout.events.onShowSendOrder, { showBtn: showBtn, disabledBtn: false });

                        $('.checkout').removeClass('l-out').addClass('l-in');

                        if (paymentId !== KCO_BETBETID) {
                            setTimeout(function () {
                                $("#customer-registration").fadeIn();
                                $("#registrationFieldContainer").fadeIn();
                                $(".checkout .customer-registration .requiredMsg").fadeIn();
                            }, 400);
                        }

                        if (!self.isLoggedIn()) {
                            PubSub.publish(mcWeb.payments.events.focusFirstName);
                            PubSub.publish(mcWeb.payments.events.processPaymentMethods, paymentId);
                        }

                    });
                });
                return;
            }

            if (paymentId === null)
                return;

            $('.payment-title').slideUp("slow");
            $('.registration-type').fadeOut("slow");
            $('.klarna-konto').fadeOut("slow");
            $('.santander-partpayment').fadeOut("slow");
            $('.santander-invoice').fadeOut("slow");
            $('.sveawebpay').fadeOut("slow");
            $('.gothia').fadeOut("slow");

            $("#customer-registration").fadeOut("slow");
            $("#OrgnrSsn").fadeOut("slow", function () {

                $('.tab-content').addClass('tab-radius');

                if (paymentId !== KCO_BETBETID) {
                    $("#mcweb-logincheckout-login-checkout").detach().appendTo("#" + paymentId);
                    $('#checkout-login').fadeIn("slow");
                }

                $('.checkout').removeClass('l-in').addClass('l-out');

                PubSub.publish(mcWeb.checkout.events.onShowSendOrder, { showBtn: true, disabledBtn: true });

                self.setLoginFocus();
            });


        });

        PubSub.subscribe(mcWeb.payments.events.onSelectedPayment, function (message, paymentId) {
            if (paymentId !== null) self.paymentId(paymentId);
        });

        PubSub.subscribe(mcWeb.login.events.onIsloggedIn, function (message, isLoggedIn) {
            self.isLoggedIn(isLoggedIn);
        });

        PubSub.subscribe(mcWeb.loginCheckout.events.onChangedEmail, function (message, email) {
            if (email.length < 3) {
                return; //No change
            }

            var customerData = self.registration.getJsonData();
            if (customerData !== null && customerData.epost !== null && customerData.epost === email) {
                return;
            }

            self.email(email);
            setCheckoutState();
        });

    };//END VM

    this.getEmail = function () {
        var el = mcWeb.loginCheckout.instances['login-checkout'];
        return el.email();
    };

    this.ResetLoginCheckoutPosision = function () {
        //Move login checkout back
        if ($(".cart-login #mcweb-logincheckout-login-checkout").length < 1) {
            $('#checkout-login').hide();
            $("#mcweb-logincheckout-login-checkout").detach().appendTo(".cart-login");
        }
    }


};
