import 'promise-polyfill/src/polyfill';
import { AjaxRenderingEngineEventTypes, AjaxRenderingEngine } from '@scripts/ajax-render-engine';

document.addEventListener('DOMContentLoaded', function () {
    if(jQuery("body.d4-loading-awaits-knockout").length <= 0){
        AjaxRenderingEngine.load();
    }
    else{//the individual page takes control of starting the loading
        PubSub.subscribe(AjaxRenderingEngineEventTypes.onKnockoutRenderingCompleted, function(message, options){
            AjaxRenderingEngine.load();
        });
    }
    
    PubSub.subscribe(AjaxRenderingEngineEventTypes.onNewAreasShown, function (message, options) {
        AjaxRenderingEngine.updateVariantMatrixField(options);
        AjaxRenderingEngine.loadLightboxFields(options);
    });


    
});
